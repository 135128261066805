



































































import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Global, Projects } from '@/store';
import { alerts_with_header_1, alerts_with_header_2, alerts_with_header_3 } from '@/store/modules/Global';

@Component({})
export default class CabinetAlertsTable extends Vue {

  @Global.State('lang') lang;
  @Projects.State('list') projectsList;

  @Prop() data;
  @Prop() project;
  @Prop() cabinetData;
  @Prop() type;
  @Prop() headers_list;

  tableData = [];
  loading = true;
  sort = null;
  utils = Utils;
  prop_utils = PropUtils;
  pagination = {
    rowsPerPage: 5,
    totalItems: 0
  };
  sortBy = 'time';
  descending = false;
  alerts_with_header_1 = alerts_with_header_1;
  alerts_with_header_2 = alerts_with_header_2;
  alerts_with_header_3 = alerts_with_header_3;
    
  mounted() {
    this.generateAlertTable();
  }

  get project_name(){
    let current_project = null;
    let name = this.project.name;
    if (this.$route.params.pid) {
      current_project = this.projectsList.find((project_data) => project_data.id === this.$route.params.pid);
      name = current_project.name;
    }

    return name;
  }

  @Watch('data')
  @Watch('type')
  generateAlertTable(){
    this.tableData = cloneDeep(this.data);
    const time_header = this.headers.find((header) => header.hasOwnProperty('sort_field') && header['sort_field'] === 'time');
    this.changeSort(time_header);
    this.pagination.totalItems = this.tableData.length;
    this.loading = false;
  }

  openAlert(alert) {
    this.$emit('handleIncidentClicked', alert);
  }

  changeSort(header_data) {
    if (header_data.hasOwnProperty('sortable') && !header_data.sortable){
      return;
    }

    const data = cloneDeep(this.tableData);

    if (this.sortBy === header_data.sort_field) {
      this.descending = !this.descending;
    }else {
      this.sortBy = header_data.sort_field;
      this.descending = false;
    }

    if (this.descending) {
      if (this.sortBy === 'time'){
        data.sort((alert1, alert2) => alert2[this.sortBy] - alert1[this.sortBy]);
      }else {
        data.sort((alert1, alert2) => alert2[this.sortBy].toString().localeCompare(alert1[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
      }
    }else {
      if (this.sortBy === 'time'){
        data.sort((alert1, alert2) => alert1[this.sortBy] - alert2[this.sortBy]);
      }else {
        data.sort((alert1, alert2) => alert1[this.sortBy].toString().localeCompare(alert2[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
      }
    }
    this.tableData = data;
  }

  get headers() {
    const start_headers = [
      { text: 'Cabinet ID', sortable: false }
    ];

    const middle_headers = [
      { text: 'Circuit Number', sort_field: 'circuit_number', sortable: true },
      { text: 'Pole ID', sort_field: 'pole', sortable: true },
      { text: 'Phase', sort_field: 'phase', sortable: true },
      { text: 'Probability', sort_field: 'probability', sortable: true }
    ];

    const end_headers = [
      { text: 'Project', sortable: false },
      { text: 'Priority', sortable: false },
      { text: 'Alert Description', sortable: false },
      { text: 'Date Time', sort_field: 'time', sortable: true }
    ];
    return start_headers.concat(middle_headers.filter((header) => this.headers_list.includes(header.sort_field))).concat(end_headers);
  }
}
